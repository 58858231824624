import "bootstrap/dist/css/bootstrap.css";
import './css/header.css';
import logo from "./assets/AppLogoV1.png"



// Here, we display our Header
export default function Header() {
  // const navigate = useNavigate();
  
  return (
    <div id="p3-header">
      <div>
        <img src={logo}></img>
      </div>
    </div>
  );
}
