import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHtml5, faCss3Alt, faSquareJs, faReact, faNode, faNodeJs } from "@fortawesome/free-brands-svg-icons";
import { faDatabase, faGem } from "@fortawesome/free-solid-svg-icons";
import laptop from "./assets/about-image.jpg"
import codeboxx from "./assets/codeboxx.jpg"
import './css/about.css';


export default function AboutDiv() {
  const listRef1 = useRef(null);
  const listRef2 = useRef(null);
  const [translationCount1, setTranslationCount1] = useState(0);
  const [translationCount2, setTranslationCount2] = useState(0);


  const handleTranslationClick = (list, direction) => {
    let translationCount;
    let setTranslationCount;
    let minTranslationCount;
    let maxTranslationCount;
  
    if (list === listRef1) {
      translationCount = translationCount1;
      setTranslationCount = setTranslationCount1;
      minTranslationCount = minTranslationCount1;
      maxTranslationCount = maxTranslationCount1;
    } else if (list === listRef2) {
      translationCount = translationCount2;
      setTranslationCount = setTranslationCount2;
      minTranslationCount = minTranslationCount2;
      maxTranslationCount = maxTranslationCount2;
    }
  
    const newTranslationCount = translationCount + direction;
    const clampedTranslationCount = Math.min(
      Math.max(newTranslationCount, minTranslationCount),
      maxTranslationCount
    );
    setTranslationCount(clampedTranslationCount);
    const translationAmount = -25 * clampedTranslationCount;
    const listElement = list.current;
    listElement.style.transform = `translateX(${translationAmount}%)`;
  };
  
  const handleNextClick = (list) => {
    handleTranslationClick(list, 1);
  };
  
  const handlePreviousClick = (list) => {
    handleTranslationClick(list, -1);
  };
  
  // Define the minimum and maximum translation counts for the FRONTEND list
  const minTranslationCount1 = 0;
  const maxTranslationCount1 = 1;
  
  // Define the minimum and maximum translation counts for the BACKEND list
  const minTranslationCount2 = 0;
  const maxTranslationCount2 = 1;



  return (
    <div id="about-div">
      <div class="custom-shape-divider-top-1685461065">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
          <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
          <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
        </svg>
      </div>
      <div id="about-top-container">
          <div className="about-image-container">
            <img src={laptop} className="about-first-image" />
            <a href="https://www.academy.codeboxx.com/full-stack-development" target="_blank">
              <div className="about-second-image-contour rotate">
                <img src={codeboxx} className="about-second-image" />
              </div>
            </a>
          </div>
        <p className="white about-text">
          I am a newly graduated developer from CodeBoxx, based in Lévis, QC.
          With a strong passion for web development, I bring dedication and enthusiasm to every project I undertake.
          My commitment to delivering quality work ensures a positive impact on my clients' businesses.
          I take pride in leveraging my experience and skills to contribute to projects I wholeheartedly believe in,
          providing creative and innovative solutions to address your needs.
          Browse through the list of <a href="#works">projects</a> I have actively participated in,
          and please don't hesitate to reach out if you have any inquiries or collaboration opportunities.
          I look forward to connecting with you and being part of your success.
        </p>
      </div>
      <div id="skills-container">
        <h4 className="white skills-title">My Skills</h4>
        <div id="frontend-container">
          <h5 className="white">Front-end</h5>
          <div className="infinite-list-container">
            <div className="infinite-list" ref={listRef1}>
              <div className="single-skill">
                <FontAwesomeIcon icon={faHtml5} className="skill-icon-size" />
                <p className="skill-name">HTML</p>
              </div>
              <div className="single-skill">
                <FontAwesomeIcon icon={faCss3Alt} className="skill-icon-size" />
                <p className="skill-name">CSS</p>
              </div>
              <div className="single-skill">
                <FontAwesomeIcon icon={faSquareJs} className="skill-icon-size" />
                <p className="skill-name">JavaScript</p>
              </div>
              <div className="single-skill">
                <FontAwesomeIcon icon={faReact} className="skill-icon-size" />
                <p className="skill-name">React</p>
              </div>
              <div className="single-skill">
                <FontAwesomeIcon icon={faReact} className="skill-icon-size" />
                <p className="skill-name">React Native</p>
              </div>
            </div>
          </div>
          <div className="skill-button-container">
            <button
              className="skill-button"
              onClick={() => handlePreviousClick(listRef1)}
              style={{ opacity: translationCount1 === 0 ? 0 : 1 }}
            >
              previous
            </button>
            <button
              className="skill-button"
              onClick={() => handleNextClick(listRef1)}
              style={{ opacity: translationCount1 === 1 ? 0 : 1 }}
            >
              next
            </button>
          </div>
        </div>
        <div id="backend-container">
          <h5 className="white">Back-end</h5>
          <div className="infinite-list-container">
            <div className="infinite-list" ref={listRef2}>
              <div className="single-skill">
                <FontAwesomeIcon icon={faNodeJs} className="skill-icon-size" />
                <p className="skill-name">Express.js</p>
              </div>
              <div className="single-skill">
                <FontAwesomeIcon icon={faNode} className="skill-icon-size" />
                <p className="skill-name">Node.js</p>
              </div>
              <div className="single-skill">
                <FontAwesomeIcon icon={faDatabase} className="skill-icon-size" />
                <p className="skill-name">MongoDB</p>
              </div>
              <div className="single-skill">
                <FontAwesomeIcon icon={faGem} className="skill-icon-size" />
                <p className="skill-name">Ruby on Rails</p>
              </div>
              <div className="single-skill">
                <FontAwesomeIcon icon={faDatabase} className="skill-icon-size" />
                <p className="skill-name">SQLite</p>
              </div>
            </div>
          </div>
          <div className="skill-button-container">
            <button
              className="skill-button"
              onClick={() => handlePreviousClick(listRef2)}
              style={{ opacity: translationCount2 === 0 ? 0 : 1 }}
            >
              previous
            </button>
            <button
              className="skill-button"
              onClick={() => handleNextClick(listRef2)}
              style={{ opacity: translationCount2 === 1 ? 0 : 1 }}
            >
              next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
