import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";


import Login from "./components/login";
import Register from "./components/register";
import Header from "./components/header";
import Navbar from "./components/navbar";
import HomePage from "./components/home";
import BloggsPage from "./components/bloggs";
import NetworkPage from "./components/network";
import AdminPage from "./components/admin";
import UserManagerPage from "./components/userManager";
import Edit from "./components/editUser";
import ContentManagerPage from "./components/contentManager";
import { useCookies } from "react-cookie";
import { SkeletonTheme } from "react-loading-skeleton";


const App = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(['cookie-name']);


  // const checkToken = () => {
  //   const token = cookies.token;
  //   fetch(`http://localhost:5000/validate_token/${token}`)
  //     .then(response => response.json())
  //     .then(result => {
  //       if (result.status !== "ok") {
  //         navigate("/project2/");
  //       }
  //     })
  // }  
  
  const location = useLocation();

  // useEffect(() => {
  //   if(location.pathname !== "/project2/register"){
  //     checkToken();
  //   }
  // }, [location.pathname]);

  return (
    <div>
      <SkeletonTheme baseColor="#e6e6e6" highlightColor="#cccccc">
        <Header />
        <div id="appDiv">
        {location.pathname !== "/project2/" && location.pathname !== "/project2/register" && <Navbar />}
          <Routes>
            <Route path="/" element={<Login />} exact/>
            <Route path="/register" element={<Register />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/bloggs" element={<BloggsPage />} />
            <Route path="/network" element={<NetworkPage />} />
            <Route path="/admin" element={<AdminPage />} />
            <Route path="/admin/user-manager" element={<UserManagerPage />} />
            <Route path="/admin/edit/:id" element={<Edit />} />
            <Route path="/admin/content-manager" element={<ContentManagerPage />} />
          </Routes>
        </div>
      </SkeletonTheme>
    </div>
  );
};

export default App;
