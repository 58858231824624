import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import './css/contact.css';


export default function ContactDiv() {

  return (
    <div id="contact-div">
      <div>
        <FontAwesomeIcon id="envelope" className="contact-icon" icon={faEnvelope} />
        <p className="white">Email: <a href = "mailto: tristanchabot10@gmail.com">tristanchabot10@gmail.com</a></p>
      </div>
      <div>
        <FontAwesomeIcon id="phone" className="contact-icon" icon={faPhone} />
        <p className="white">Phone: <a href="tel:5817454593">581-745-4593</a></p>
      </div>
    </div>
  );
}
