import './css/home.css';


export default function HomePage() {  

  return (
    <div id="p3-home-page" className="custom-shape-divider-top-1684887599">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
        <h1 id="p3-homeTitle">Project overview</h1>
      </svg>
      <div id="videos-container">
        {/* *** LOGIN and NAVIGATION *** */}
        <div className="overview-block">
          <div className="my-container-left video-container">
            <h2>Login and Navigation</h2>
            <iframe
              className="p3-first-video-container"
              src="https://www.youtube.com/embed/EywrgjhZXaI"
              frameborder="0"
              allowfullscreen
            />
          </div>
          <div className="my-container-right text-container">
            <div className="video-description-container">
              <p>
                The backend of the application is built using <strong>Ruby on Rails</strong>.
                For user authentication, I utilized the <strong>"devise" gem</strong>.
                Upon entering the correct credentials, the user is directed to different pages based on their role.
                If the user is solely a <strong>customer</strong>, they are redirected to the restaurants page.
                If they are exclusively a <strong>courier</strong>, they are redirected to the courier's order page.
                However, if the user has both <strong>customer and courier</strong> roles, they are directed to a navigation page where they can choose their desired destination.
              </p>
            </div>
          </div>
        </div>
        {/* *** RESTAURANT FILTER *** */}
        <div className="overview-block flip">
          <div className="my-container-left text-container">
            <div className="video-description-container">
              <p>
                The application includes two filters for restaurants.
                The first filter allows users to sort restaurants based on their <strong>rating</strong>, while the second filter enables sorting by <strong>price</strong>.
                By applying these filters, the application displays only those restaurants that fall within the specified rating and price ranges.
                Additionally, users have the <strong>flexibility</strong> to apply both filters <strong>simultaneously</strong>, refining their search even further.
                This functionality ensures that users can easily find and view restaurants that meet their specific rating and price preferences.
              </p>
            </div>
          </div>
          <div className="my-container-right video-container">
            <h2>Restaurants filters</h2>
            <iframe
              className="p3-first-video-container"
              src="https://youtube.com/embed/2dXG9ek6FgQ"
              frameborder="0"
              allowfullscreen
            />
          </div>
        </div>
        {/* *** PASSING AN ORDER *** */}
        <div className="overview-block">
          <div className="my-container-left video-container">
            <h2>Passing an Order</h2>
            <iframe
              className="p3-first-video-container"
              src="https://youtube.com/embed/UOcLKTEr7k8"
              frameborder="0"
              allowfullscreen
            />
          </div>
          <div className="my-container-right text-container">
            <div className="video-description-container">
              <p>Upon clicking on a restaurant, its <strong>menu</strong> is displayed, allowing users to explore the available items.
                The button to create an order remains <strong>disabled</strong> until items are added to the order.
                Once the user adds items to their order, the button becomes <strong>enabled</strong>, allowing them to proceed with creating the order.
              </p>
              <p>
                After clicking the button, users are presented with an <strong>order summary</strong>, providing a comprehensive overview of their selected items.
                At this stage, users have the option to review the order details before confirming it.
              </p>
              <p>
                To ensure confirmation is received promptly, users are given the choice to receive <strong>confirmation</strong> notifications via <strong>email</strong> and <strong>SMS</strong>,
                providing them with an additional level of convenience and peace of mind.
              </p>
            </div>
          </div>
        </div>
        {/* *** COURIER'S ASSIGNED ORDERS *** */}
        <div className="overview-block  flip">
          <div className="my-container-left text-container">
            <div className="video-description-container">
              <p>
                Upon logging in as a <strong>courier</strong>, the system automatically redirects you to your assigned orders.
                Here, you can access a comprehensive list of all the orders assigned to you,
                along with pertinent information such as order <strong>status</strong> and <strong>details</strong>.
                Notably, the information displayed includes the restaurant name, delivery destination,
                and the scheduled date for delivery.
              </p>
              <p>
                Regarding the order status, the system provides an <strong>interactive</strong> feature.
                By clicking on the status, you can seamlessly <strong>update</strong> it to reflect the progress of the order.
                Initially set as <strong>"pending"</strong>, the status can be updated to <strong>"in progress"</strong> once you begin the delivery process.
                Finally, after successfully delivering the order, the status can be further updated to <strong>"delivered"</strong>.
              </p>
              <p>
                This intuitive and efficient order management system ensures that you have a clear overview of your assigned orders as a courier.
                By seamlessly updating the order status, you can effectively track the progress and stay informed about the status of each delivery.
              </p>
            </div>
          </div>
          <div className="my-container-right video-container">
            <h2>Courier's assigned Orders</h2>
            <iframe
              className="p3-first-video-container"
              src="https://youtube.com/embed/rhGHrKtn85M"
              frameborder="0"
              allowfullscreen
            />
          </div>
        </div>
        {/* *** UPDATING ACCOUNT *** */}
        <div className="overview-block">
          <div className="my-container-left video-container">
            <h2>Updating Account Information</h2>
            <iframe
              className="p3-first-video-container"
              src="https://youtube.com/embed/Stra0pgBGR8"
              frameborder="0"
              allowfullscreen
            />
          </div>
          <div className="my-container-right text-container">
            <div className="video-description-container">
              <p>
                The <strong>account page</strong> remains consistent whether you're logged in as a <strong>customer</strong> or a <strong>courier</strong>.
                As a customer or as a courier, you can update your <strong>email</strong> and <strong>phone</strong>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
