import { Route, Routes } from "react-router-dom";
import { SkeletonTheme } from "react-loading-skeleton";


import Header from "./components/header";
import HomePage from "./components/home";


const App = () => {
  // const navigate = useNavigate();

  return (
    <div>
      <SkeletonTheme baseColor="#e6e6e6" highlightColor="#cccccc">
        <Header />
        <div>
          <Routes>
            <Route path="/" element={<HomePage />} exact/>
          </Routes>
        </div>
      </SkeletonTheme>
    </div>
  );
};

export default App;
