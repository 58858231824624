import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";


import Home from "./components/home";
import Navbar from "./components/navbar";

// *** ROCKET ELEVATORS ***
import Project1 from './components/RocketElevators/App';
import Project2 from './components/CodeBloggs/App';
import Project3 from './components/RocketFoodDelivery/App';
import Project4 from './components/SnapCycle/App';


function App() {
  return (
    <Router>
      <div className="App">
        <Navbar/>
        <main>
          <Routes>
            <Route path="/" element={<Home />} exact/>
            {/* <Route path="/project1/*" element={<Project1 />} />
            <Route path="/project2*" element={<Project2 />} /> */}
            <Route path="/project3*" element={<Project3 />} />
            {/* <Route path="/project4*" element={<Project4 />} /> */}
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;