import React, { useRef, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './css/navbar.css';


export default function NavScrollExample() {
  const navigate = useNavigate();
  const navbarRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const navbarHeight = navbarRef.current.getBoundingClientRect().height;
    document.documentElement.style.setProperty('--navbar-height', `${navbarHeight}px`);
  }, [location]);

  return (
    <Navbar bg="dark" expand="lg" sticky="top" ref={navbarRef}>
      <Container fluid>
        <Navbar.Brand className="brand margin-right" style={{ marginLeft: "20px" }}>My Portfolio</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll" style={{ marginRight: "20px" }}>
          <Nav
            className="ms-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          >
            <Nav.Link href="#home-page" className="white nav-hover" onClick={() => navigate("/")}>Home</Nav.Link>
            <Nav.Link href="#about" className="white nav-hover" onClick={() => navigate("/#about")}>About</Nav.Link>
            <Nav.Link href="#works" className="white nav-hover" onClick={() => navigate("/#works")}>Works</Nav.Link>
            <Nav.Link href="#contact" className="white nav-hover" onClick={() => navigate("/#contact")}>Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}