import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import portrait from "./assets/AvatarMaker.png"
import './css/home.css';
import About from "./about";
import Contact from "./contact";


export default function HomePage() {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [is370, setIs370] = useState(false);
  const [is317, setIs317] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      setIs370(width <= 370);
      setIs317(width <= 317);
    };
  
    handleResize();
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const navigateRocketElevators = () => {
    navigate("/project1");
    window.scrollTo(0, 0);
  }

  const navigateCodeBloggs = () => {
    navigate("/project2");
    window.scrollTo(0, 0);
  }

  const navigateRocketFoodDelivery = () => {
    navigate("/project3");
    window.scrollTo(0, 0);
  }

  const navigateSnapCycle = () => {
    navigate("/project4");
    window.scrollTo(0, 0);
  }

  return (
    <div id="home-page" style={{ height: "100%" }}>
      <div id="top-container">
        <div id="intro-message">
          {is370 ? (
            <div>
              {is317 ? (
                <>
                  <h5 className="intro-text">Hi, I'm Tristan.</h5>
                  <h5 className="intro-text">A Full Stack web developer with</h5>
                  <div className="changing-text-container">
                    <h5 className="intro-text">a passion for</h5>
                    <h5>
                      <span className="intro-effect typewriter thick"></span>
                    </h5>
                  </div>
                </>
              ) : (
                <>
                  <h2 className="intro-text">Hi, I'm Tristan.</h2>
                  <h2 className="intro-text">A Full Stack web developer with</h2>
                  <div className="changing-text-container">
                    <h2 className="intro-text">a passion for</h2>
                    <h2>
                      <span className="intro-effect typewriter thick"></span>
                    </h2>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div>
              <h1 className="intro-text">Hi, I'm Tristan.</h1>
              <h1 className="intro-text">A Full Stack web developer with</h1>
              <div className="changing-text-container">
                <h1 className="intro-text">a passion for</h1>
                <h1>
                  <span className="intro-effect typewriter thick"></span>
                </h1>
              </div>
            </div>
          )}
          <a href="https://www.linkedin.com/in/tristan-chabot-310b40268/" target="_blank"><FontAwesomeIcon id="linkedIn" className="contact-icon first-icon" icon={faLinkedin} /></a>
          <a href="https://github.com/tristanChabot1?tab=repositories" target="_blank"><FontAwesomeIcon id="gitHub" className="contact-icon" icon={faGithub} /></a>
        </div>
        <div
          id="portrait-container"
          onMouseEnter={handleHover}
          onMouseLeave={handleMouseLeave}
        >
          <div id="text-bubble" style={{ opacity: isHovered ? 1 : "", transition: "0.5s" }}>
            <p className="white">Welcome!</p>
            <p className="white">If you have any questions,</p>
            <p className="white">feel free to <a className="contact-color" href="#contact">contact</a> me!</p>
          </div>
          <div id="small-bubble" style={{ opacity: isHovered ? 1 : "", transition: "0.5s" }}/>
          <img
            src={portrait}
            className="image-style"
          />
        </div>
      </div>
      <div id="about">
        <h3 className="white">About</h3>
        <About />
      </div>
      <div id="works">
        <h3 className="white">Works</h3>
        <div class="custom-shape-divider-top-1685461065">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
            <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
            <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
          </svg>
        </div>
        <div id="works-container">
          <div id="RocketFoodDelivery" className="clickHover single-work-container" onClick={navigateRocketFoodDelivery}>
            <div className="project-hover">
              <h4 className="project-font">Rocket Food Delivery</h4>
            </div>
         </div>
         <div id="SnapCycle" className="clickHover single-work-container" /*onClick={navigateSnapCycle}*/>
            <div className="project-unfinished">
              <h4 className="project-font">SnapCycle</h4>
              <h5 className="project-font">Available soon</h5>
            </div>
         </div>
          <div id="RocketElevators" className="clickHover single-work-container" /*onClick={navigateRocketElevators}*/>
            <div className="project-unfinished">
              <h4 className="project-font">Rocket Elevators</h4>
              <h5 className="project-font">Available soon</h5>
            </div>
          </div>
          <div id="CodeBloggs" className="clickHover single-work-container" /*onClick={navigateCodeBloggs}*/>
            <div className="project-unfinished">
              <h4 className="project-font">CodeBloggs</h4>
              <h5 className="project-font">Available soon</h5>
            </div>
          </div>
        </div>
      </div>
      <div id="contact">
        <h3 className="white">Contact me!</h3>
        <Contact />
      </div>
    </div>
  );
}
