import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useCookies } from "react-cookie";


function ModalPost(props) {
  const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
  const [show, setShow] = useState(false);
  const [showError, setShowError] = useState(false)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const buttonStyle = { display: props.buttonDisplay }

  const submit = async () => {
    const postContent = {
      content: document.getElementById("post_content").value,
      user_id: cookies.userID
    };
    console.log(postContent);
    const res = await fetch("http://localhost:5000/post_publish", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postContent),
    }).catch((error) => {
      window.alert(error);
      return
    });
    if (res.status !== 200) {
      setShowError(true)
      setTimeout(() => {
        setShowError(false);
      }, 4000);
    }
  }

  const handleConfirm = () => {
    submit();
    handleClose();
    window.location.reload();
  };
  return (
    <>
      <button variant="button" style={buttonStyle} onClick={handleShow}>
        {props.buttonText}
      </button>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <form>
        <Modal.Body>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              id="post_content"
              name="post_content"
              placeholder="Write a blog post."
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={props.confirmVariant} onClick={handleConfirm}>
            {props.confirmText}
          </Button>
        </Modal.Footer>
        </form>
        <p style={{color: "red", display: showError ? "block" : "none"}}>An error occured</p>
      </Modal>
    </>  
  );
}

ModalPost.defaultProps = {
  confirmText: "Post",
  confirmVariant: "warning",
}

export default ModalPost;